<template>
  <b-row>
    <b-col>
      <b-card title="Filters">
        <b-form v-on:keyup.enter="filterData">

          <b-row>
            <b-col>
              <b-form-group
                label="Document number"
                label-for="document-number"
              >
                <b-form-input
                  id="document-number"
                  v-model="filters.document_number"
                  name="document-number"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Parcel tracking number"
                label-for="parcel-tracking-number"
              >
                <b-form-input
                  id="parcel-tracking-number"
                  v-model="filters.tracking_number"
                  name="parcel-tracking-number"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Container number"
                label-for="container-number"
              >
                <b-form-input
                  id="container-number"
                  v-model="filters.container_code"
                  name="container-number"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Date range"
                label-for="date-range"
              >
                <flat-pickr
                  id="date-range"
                  v-model="filters.dateRange"
                  class="form-control"
                  :config="{ mode: 'range' }"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  v-model="selectedFilterStatus"
                  :options="statusOptions"
                  :clearable="false"
                  input-id="status"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="MRN"
                label-for="mrn"
              >
                <b-form-input
                  id="mrn"
                  v-model="filters.mrn"
                  name="mrn"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <!-- Company name filter -->
            <b-col v-if="companyList.length > 1">
              <b-form-group
                label="Company"
                label-for="company"
              >
                <v-select
                  id="company"
                  v-model="filters.companyIds"
                  :reduce="option => option.value"
                  label="label"
                  multiple
                  :options="suggestionList"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <b-button
                type="button"
                variant="primary"
                @click="filterData()"
              >
                Filter
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <shipments-table
        :table-data="data.shipments"
        :event-hub="data.eventHub"
        :statuses="statusOptions"
        :show-logo-column="companyList.length > 1"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Vue from 'vue'
import vSelect from 'vue-select'
import ShipmentsTable from '@/views/shipments/ShipmentsTable.vue'
import { parseFilters, getSelectedCompanies, updateSelectedCompanies } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    ShipmentsTable,
    BCard,
    vSelect,
  },
  data() {
    return {
      statusOptions: [
        { label: 'N/A', value: '', variant: '' },
        { label: 'Created', value: 'created', variant: 'info' },
        { label: 'In progress', value: 'in-progress', variant: 'info' },
        { label: 'Done', value: 'done', variant: 'light-success' },
        { label: 'Parsed', value: 'parsed', variant: 'success' },
        { label: 'Archived', value: 'truncated', variant: 'secondary' },
        { label: 'Attention', value: 'attention', variant: 'warning' },
      ],
      filters: {
        document_number: '',
        container_code: '',
        tracking_number: '',
        mrn: '',
        dateRange: '',
        status: '',
        companyIds: getSelectedCompanies(),
      },
      selectedFilterStatus: null,
      data: {
        eventHub: new Vue(),
        shipments: [
        ],
      },
      companyList: [],
    }
  },
  computed: {
    suggestionList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
  },
  created() {
    this.$data.companyList = this.$profile().data.user.companies
  },
  methods: {
    filterData() {
      if (this.selectedFilterStatus) {
        this.filters.status = this.selectedFilterStatus.value
      }
      this.data.eventHub.$emit('updateFilters', parseFilters(this.$data.filters))
      updateSelectedCompanies(this.$data.filters.companyIds)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
